import React, {useEffect} from "react";
import {Unity,useUnityContext} from 'react-unity-webgl'


const UnityLoader = ({loader, frame, data, wasm}) => {

    const { unityProvider } = useUnityContext({
        loaderUrl: loader,
        dataUrl: data,
        frameworkUrl: frame,
        codeUrl: wasm,
     
      });

      
      useEffect(()=>{
        
        // Load any additional scripts here if necessary
        const inworldMicrophoneScript = document.createElement('script');
        inworldMicrophoneScript.src = './unityaudio/InworldMicrophone.js';
        inworldMicrophoneScript.async = true;
        document.body.appendChild(inworldMicrophoneScript);
      
      
      //audio
      return () => {
         document.body.removeChild(inworldMicrophoneScript);
       };
      
      },[])  
      // unityProvider.setInitialisationError((er)=>{
      //   console.log(er)
      // })
    
     
      

    return ( 
        

<Unity  unityProvider={unityProvider}  className="unityCanvas"/>

        
     );
}
 
export default UnityLoader;