import React from 'react';
import { useEffect, useState, useRef } from 'react';
import UnityLoader from './unityLoader';
import { Spinner, ProgressBar } from 'react-bootstrap';
import './App.css';
const LoadingPage = () => {
    
    //when page is loaded, begin fetching data
    // via a blob
    
    const [depLoaded, setDepLoaded] = useState(0) 
    const [loadingVals, setLoadingVals] = useState("")
    const [loaderUrl, setLoaderUrl] = useState("")
    const [dataUrl, setDataUrl] = useState("")
    const [frameUrl,setFrameUrl] = useState("")
    const [wasmUrl, setWasmUrl] = useState("")
    const [dataCurr, setDataCurr] = useState(0)
    const [percentageBar, setPercentBar] = useState(0)

    const teamMembers = [
        { name: 'Dr. Carl White', bio: 'Dr. Carl White is the Principal Investigator for the research team. He is The Special Advisor For Strategic Enrollment Partnerships and a tenured faculty member in the Department of Electrical and Computer Engineering. He has over 25 years of experience in research and development, and has brought in over 25 million dollars in research funding to the university.',
        Image:'./dr_white.jpg' },
        { name: 'David King', bio: 'David is the Co-PI and the Lead XR Developer for the research team. He Recieved a Bachelors of Science in Electrical Engineering at Morgan State University. ', Image:'./david.png' },
    
        { name: 'Dr. Melvin Bayne', bio: 'Dr. Bayne is the System Integrator and Senior XR Developer for the research team. He recieved a Doctorate of Engineering from Morgan State University.',
         Image:'./Melvin.jpg' },
        { name: 'Shaun Phillips', bio: 'Shaun is the Junior XR Developer for the research team. He is a recent graduate from Morgan State University with a Bachelors of Science in Electrical Engineering.',
        Image:'./Shaun_jpg.jpg' },
        { name: 'Ryson Vessels', bio: 'Ryson is the Undergraduate XR Developer for the research team. He is a Freshman at Morgan State University majoring in Electrical Engineering.',
        Image:'./Ryson.jpg' },
      ];


    // const isMounted = useRef(un)
    let loadingUrl  = {
        data: "",
        framework: "",
        loader: "",
        wasm: ""
    }

    let currIndex = 0
    useEffect(()=>{
        if(dataUrl != "")
        {
            // setDataCurr(dataCurr + 1)
        }
        
    },[dataUrl])

    const updateCurrIndex = async ()=>{
        await setTimeout(()=>{
            setDataCurr(currIndex)
            console.log("set Timeout: ")
        },1000)
    } 

    useEffect(()=>{
        console.log("percentage: ", percentageBar)
    },[percentageBar])

    const loadDependencies = async ()=>{
        // http://unitymoodledemo-env.eba-wsekxsup.us-east-1.elasticbeanstalk.com/
        let serverurl = "http://unitymoodledemo-env.eba-wsekxsup.us-east-1.elasticbeanstalk.com/"
        serverurl = "https://msuenvironmentdemo.com/"
        
        let localurl="http://localhost:3333/"
        // serverurl = localurl

       await fetch(serverurl+'getdata',{method: "GET", headers:{'Accept': 'application/octet-stream'}})
        .then( async (res)=>{
            
            const bodyReader = res.body.getReader()
            const contentLen = res.headers.get('Content-Length')
            let totalLen = 0
            let recievedLength = 0
            if(typeof contentLen === 'string')
            {
                totalLen = parseInt(contentLen)
                console.log("total length: ",totalLen)
            }
            
            console.log(contentLen)
            const chunks = []
            while(true)
            {
                const {done,value} = await bodyReader.read()
                

            
                if(done)
                {
                    
                    break

                }
                console.log("after if, value of done: ", done)
                let chunkLength  = 0
                if(typeof value.length ==="number" )
                {
                    console.log("val length: ",value.length)
                    chunkLength = parseInt(value.length)
                }
                recievedLength = recievedLength + chunkLength
                setPercentBar((recievedLength/totalLen)*100)
                
                 chunks.push(value)
            }
            console.log("break happened")

            const blob1 = new Blob(chunks)
            const bUrl = URL.createObjectURL(blob1)
            console.log("loading data: ", bUrl)
            setDataUrl(bUrl)      
            currIndex += 1
            setDataCurr(1)
 })


        //    const reader = res.body.getReader();

        //    let contLength = res.headers.get('Content-Length')

        //    console.log("content len: ", contLength)

        //     let receivedData = 0

        //     let chunks = []
        //     while(true) 
        //     {
        //         const {done, value} = await reader.read();
              
        //         if (done) {
        //           break;
        //         }

        //         chunks.push(value);
        //         receivedData += value.length;

        //         console.log(`Received getdata amount ${receivedData} of ${contLength}`)
        //     }
              

        //     return res.blob()
       
        // .then((myblob)=>{

            
        //     return URL.createObjectURL(myblob)
        // })
        // .then((objUrl)=>{
        //     console.log("loading data: ", objUrl)
        //     setDataUrl(objUrl)      
        //     currIndex += 1
        //     setDataCurr(1)

        // })



    //download with progress bar
        
    // await fetch(serverurl+'getdata',{method: "GET", headers:{'Accept': 'application/octet-stream'}})
    // .then((res)=>{

    //     const contentLength =  res.headers.get('content-length')
    //     let loaded = 0
         
    //     return new Response (
    //         new ReadableStream({
    //             start
    //         })
    //     )
        
       
    //     return res.blob()
    // })
    // .then((myblob)=>{

        
    //     return URL.createObjectURL(myblob)
    // })
    // .then((objUrl)=>{
    //     console.log("loading data: ", objUrl)
    //     setDataUrl(objUrl)      
    //     currIndex += 1
    //     setDataCurr(1)

    // })



        await fetch(serverurl+'getframe',{method: "GET", headers:{'Accept': 'application/javascript'}})
        .then( async (res)=>{
            
            const bodyReader = res.body.getReader()
            const contentLen = res.headers.get('Content-Length')
            let totalLen = 0
            let recievedLength = 0
            if(typeof contentLen === 'string')
            {
                totalLen = parseInt(contentLen)
                console.log("total length: ",totalLen)
            }
            
            console.log(contentLen)
            const chunks = []
            while(true)
            {
                const {done,value} = await bodyReader.read()
                

            
                if(done)
                {
                    
                    break

                }
                console.log("after if, value of done: ", done)
                let chunkLength  = 0
                if(typeof value.length ==="number" )
                {
                    console.log("val length: ",value.length)
                    chunkLength = parseInt(value.length)
                }
                recievedLength = recievedLength + chunkLength
                setPercentBar((recievedLength/totalLen)*100)
                
                 chunks.push(value)
            }
            console.log("break happened")

            const blob1 = new Blob(chunks)
            const bUrl = URL.createObjectURL(blob1)
            console.log("loading vals: ", bUrl)
            setFrameUrl(bUrl)
            currIndex += 1
            setDataCurr(2)    
        })
     


       await  fetch(serverurl+'getwasm',{method: "GET", headers:{'Accept': 'application/wasm'}})
        .then(async (res)=>{
           
            const bodyReader = res.body.getReader()
            const contentLen = res.headers.get('Content-Length')
            let totalLen = 0
            let recievedLength = 0
            if(typeof contentLen === 'string')
            {
                totalLen = parseInt(contentLen)
                console.log("total length: ",totalLen)
            }
            
            console.log(contentLen)
            const chunks = []
            while(true)
            {
                const {done,value} = await bodyReader.read()
                

            
                if(done)
                {
                    
                    break

                }
                console.log("after if, value of done: ", done)
                let chunkLength  = 0
                if(typeof value.length ==="number" )
                {
                    console.log("val length: ",value.length)
                    chunkLength = parseInt(value.length)
                }
                recievedLength = recievedLength + chunkLength
                setPercentBar((recievedLength/totalLen)*100)
                
                 chunks.push(value)
            }
            console.log("break happened")

            const blob1 = new Blob(chunks)
            const bUrl = URL.createObjectURL(blob1)
            console.log("loading vals: ", bUrl)
            setWasmUrl(bUrl)
            currIndex += 1
            setDataCurr(3)  
        })
       

        
        console.log("after get wasm")
        await fetch(serverurl+'getloader',{method: "GET", headers:{'Accept': 'application/javascript'}})
        .then(async (res)=>{
            
            const bodyReader = res.body.getReader()
            const contentLen = res.headers.get('Content-Length')
            let totalLen = 0
            let recievedLength = 0
            if(typeof contentLen === 'string')
            {
                totalLen = parseInt(contentLen)
                console.log("total length: ",totalLen)
            }
            
            console.log(contentLen)
            const chunks = []
            while(true)
            {
                const {done,value} = await bodyReader.read()
                

            
                if(done)
                {
                    
                    break

                }
                console.log("after if, value of done: ", done)
                let chunkLength  = 0
                if(typeof value.length ==="number" )
                {
                    console.log("val length: ",value.length)
                    chunkLength = parseInt(value.length)
                }
                recievedLength = recievedLength + chunkLength
                setPercentBar((recievedLength/totalLen)*100)
                
                 chunks.push(value)
            }
            console.log("break happened")

            const blob1 = new Blob(chunks)
            const bUrl = URL.createObjectURL(blob1)
            console.log("loading vals: ", bUrl)
            setLoaderUrl(bUrl)
            currIndex += 1
            setDataCurr(4)
            setDepLoaded(1)  
        })
     
       
         
     }


    useEffect(()=>{

        console.log( "data Curr: " ,dataCurr)
        
        

    },[dataCurr])

    let CountMounts = 0

    useEffect(()=>{
        
 
   

        if(CountMounts == 0)
        {
           loadDependencies() 
           CountMounts = 1
        }


        
    },[])   


    const UnityStage = ()=>{
        if(depLoaded == 0)
        {
            return (
                // spinner
                <div className='LoadingContainer'>

                  <div className='loading'  > 
                    {/* <Spinner className="LoadingSpinner" animation="border" style={{ color:"black",textAlign: "center" }}/> */}

                    <div className='loadText'> Loading files : {dataCurr} / 4 </div>
                    {/* progress bar */}
                    <ProgressBar className='progBar' animated = {true} now={percentageBar} label={`${percentageBar.toPrecision(4)}%`}/>
                    
                    <div className="team-container">
      <h2>Our Team</h2>
      <div className="members-container">
        {teamMembers.map((member, index) => (
          <div key={index} className="member-card">
            <img src={member.Image} alt={member.name} />
            <h3>{member.name}</h3>
            <p>{member.bio}</p>
          </div>
        ))}
      </div>
    </div>
                
                </div>  

                </div>
                
            )
        }
        else if (depLoaded == 1)
        {
            console.log("data curr when deploaded = 1: ", dataCurr)
            
           return(
           
           <div className="App unityDiv"> 
           <UnityLoader  loader={loaderUrl} data={dataUrl} wasm={wasmUrl} frame={frameUrl} />
           </div>
       
    )

    

        }
    }

    // const npm

    
    return ( 
        
    <div >

        {UnityStage()}
        {/* <div>
            
           
           
           
        </div> */}
    </div> 
    );
}
 
export default LoadingPage;